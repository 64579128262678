export const AboutMePersonal = () => import('../../components/AboutMe/Personal.vue' /* webpackChunkName: "components/about-me-personal" */).then(c => wrapFunctional(c.default || c))
export const AboutMe = () => import('../../components/AboutMe/index.vue' /* webpackChunkName: "components/about-me" */).then(c => wrapFunctional(c.default || c))
export const About1 = () => import('../../components/AboutUs/About1.vue' /* webpackChunkName: "components/about1" */).then(c => wrapFunctional(c.default || c))
export const CommentsCommentList = () => import('../../components/Comments/CommentList.vue' /* webpackChunkName: "components/comments-comment-list" */).then(c => wrapFunctional(c.default || c))
export const CommentsLeaveComment = () => import('../../components/Comments/LeaveComment.vue' /* webpackChunkName: "components/comments-leave-comment" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceCalendar = () => import('../../components/CreateSeance/Calendar.vue' /* webpackChunkName: "components/create-seance-calendar" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceDone = () => import('../../components/CreateSeance/Done.vue' /* webpackChunkName: "components/create-seance-done" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceError = () => import('../../components/CreateSeance/Error.vue' /* webpackChunkName: "components/create-seance-error" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceInformation = () => import('../../components/CreateSeance/Information.vue' /* webpackChunkName: "components/create-seance-information" */).then(c => wrapFunctional(c.default || c))
export const CreateSeancePackages = () => import('../../components/CreateSeance/Packages.vue' /* webpackChunkName: "components/create-seance-packages" */).then(c => wrapFunctional(c.default || c))
export const CreateSeancePurchase = () => import('../../components/CreateSeance/Purchase.js' /* webpackChunkName: "components/create-seance-purchase" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceRegistered = () => import('../../components/CreateSeance/Registered.vue' /* webpackChunkName: "components/create-seance-registered" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceTransfer = () => import('../../components/CreateSeance/Transfer.vue' /* webpackChunkName: "components/create-seance-transfer" */).then(c => wrapFunctional(c.default || c))
export const CreateSeanceUserInformation = () => import('../../components/CreateSeance/UserInformation.vue' /* webpackChunkName: "components/create-seance-user-information" */).then(c => wrapFunctional(c.default || c))
export const CreateSeance = () => import('../../components/CreateSeance/index.vue' /* webpackChunkName: "components/create-seance" */).then(c => wrapFunctional(c.default || c))
export const EventItemVideo = () => import('../../components/EventItemVideo/index.vue' /* webpackChunkName: "components/event-item-video" */).then(c => wrapFunctional(c.default || c))
export const CategoriesCategoryCard = () => import('../../components/Categories/CategoryCard.vue' /* webpackChunkName: "components/categories-category-card" */).then(c => wrapFunctional(c.default || c))
export const Categories = () => import('../../components/Categories/index.vue' /* webpackChunkName: "components/categories" */).then(c => wrapFunctional(c.default || c))
export const EventListOfUsClosestEvent = () => import('../../components/EventListOfUs/ClosestEvent.vue' /* webpackChunkName: "components/event-list-of-us-closest-event" */).then(c => wrapFunctional(c.default || c))
export const EventList = () => import('../../components/EventListOfUs/List.vue' /* webpackChunkName: "components/event-list" */).then(c => wrapFunctional(c.default || c))
export const EventListItem = () => import('../../components/EventListOfUs/ListItem.vue' /* webpackChunkName: "components/event-list-item" */).then(c => wrapFunctional(c.default || c))
export const EventListOfUs = () => import('../../components/EventListOfUs/index.vue' /* webpackChunkName: "components/event-list-of-us" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/Faq/index.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const Feedbacks = () => import('../../components/Feedbacks/index.vue' /* webpackChunkName: "components/feedbacks" */).then(c => wrapFunctional(c.default || c))
export const ForgetPassword = () => import('../../components/ForgetPassword/index.vue' /* webpackChunkName: "components/forget-password" */).then(c => wrapFunctional(c.default || c))
export const HeocademySiteBuilderContentCreator = () => import('../../components/HeocademySiteBuilder/ContentCreator.vue' /* webpackChunkName: "components/heocademy-site-builder-content-creator" */).then(c => wrapFunctional(c.default || c))
export const HeocademySiteBuilderRemoveContent = () => import('../../components/HeocademySiteBuilder/RemoveContent.vue' /* webpackChunkName: "components/heocademy-site-builder-remove-content" */).then(c => wrapFunctional(c.default || c))
export const HeocademySiteBuilderUploadImageArea = () => import('../../components/HeocademySiteBuilder/UploadImageArea.vue' /* webpackChunkName: "components/heocademy-site-builder-upload-image-area" */).then(c => wrapFunctional(c.default || c))
export const HeocademySiteBuilderUploadVideoArea = () => import('../../components/HeocademySiteBuilder/UploadVideoArea.vue' /* webpackChunkName: "components/heocademy-site-builder-upload-video-area" */).then(c => wrapFunctional(c.default || c))
export const HeocademySiteBuilderImageUploadModal = () => import('../../components/HeocademySiteBuilder/imageUploadModal.vue' /* webpackChunkName: "components/heocademy-site-builder-image-upload-modal" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner2 = () => import('../../components/Home/Banner-2.vue' /* webpackChunkName: "components/home-banner2" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner3 = () => import('../../components/Home/Banner-3.vue' /* webpackChunkName: "components/home-banner3" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/Home/Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeBlogs = () => import('../../components/Home/Blogs.vue' /* webpackChunkName: "components/home-blogs" */).then(c => wrapFunctional(c.default || c))
export const HomeCategory2 = () => import('../../components/Home/Category-2.vue' /* webpackChunkName: "components/home-category2" */).then(c => wrapFunctional(c.default || c))
export const HomeColorPalette = () => import('../../components/Home/ColorPalette.vue' /* webpackChunkName: "components/home-color-palette" */).then(c => wrapFunctional(c.default || c))
export const HomeComponentPalette = () => import('../../components/Home/ComponentPalette.vue' /* webpackChunkName: "components/home-component-palette" */).then(c => wrapFunctional(c.default || c))
export const HomeDonetors = () => import('../../components/Home/Donetors.vue' /* webpackChunkName: "components/home-donetors" */).then(c => wrapFunctional(c.default || c))
export const HomeMoreAboutMe = () => import('../../components/Home/MoreAboutMe.vue' /* webpackChunkName: "components/home-more-about-me" */).then(c => wrapFunctional(c.default || c))
export const HomePopularCategories = () => import('../../components/Home/PopularCategories.vue' /* webpackChunkName: "components/home-popular-categories" */).then(c => wrapFunctional(c.default || c))
export const HomePopularProducts = () => import('../../components/Home/PopularProducts.vue' /* webpackChunkName: "components/home-popular-products" */).then(c => wrapFunctional(c.default || c))
export const HomeSeancesList = () => import('../../components/Home/SeancesList.vue' /* webpackChunkName: "components/home-seances-list" */).then(c => wrapFunctional(c.default || c))
export const HomeShowcase = () => import('../../components/Home/Showcase.vue' /* webpackChunkName: "components/home-showcase" */).then(c => wrapFunctional(c.default || c))
export const HomeSponsor = () => import('../../components/Home/Sponsor.vue' /* webpackChunkName: "components/home-sponsor" */).then(c => wrapFunctional(c.default || c))
export const HomeTypes = () => import('../../components/Home/Types.vue' /* webpackChunkName: "components/home-types" */).then(c => wrapFunctional(c.default || c))
export const HomeWebSiteComingSoon = () => import('../../components/Home/WebSiteComingSoon.vue' /* webpackChunkName: "components/home-web-site-coming-soon" */).then(c => wrapFunctional(c.default || c))
export const HomeWebSiteCounts = () => import('../../components/Home/WebSiteCounts.vue' /* webpackChunkName: "components/home-web-site-counts" */).then(c => wrapFunctional(c.default || c))
export const HomeWebSiteVideo = () => import('../../components/Home/WebSiteVideo.vue' /* webpackChunkName: "components/home-web-site-video" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../../components/Home/index.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/Login/index.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const MyProfileAnswerToOffer = () => import('../../components/MyProfile/AnswerToOffer.vue' /* webpackChunkName: "components/my-profile-answer-to-offer" */).then(c => wrapFunctional(c.default || c))
export const MyProfileBooks = () => import('../../components/MyProfile/Books.vue' /* webpackChunkName: "components/my-profile-books" */).then(c => wrapFunctional(c.default || c))
export const MyProfileChangePassword = () => import('../../components/MyProfile/ChangePassword.vue' /* webpackChunkName: "components/my-profile-change-password" */).then(c => wrapFunctional(c.default || c))
export const MyProfileCourses = () => import('../../components/MyProfile/Courses.vue' /* webpackChunkName: "components/my-profile-courses" */).then(c => wrapFunctional(c.default || c))
export const MyProfileEvents = () => import('../../components/MyProfile/Events.vue' /* webpackChunkName: "components/my-profile-events" */).then(c => wrapFunctional(c.default || c))
export const MyProfileGetOffer = () => import('../../components/MyProfile/GetOffer.vue' /* webpackChunkName: "components/my-profile-get-offer" */).then(c => wrapFunctional(c.default || c))
export const MyProfilePayment = () => import('../../components/MyProfile/Payment.js' /* webpackChunkName: "components/my-profile-payment" */).then(c => wrapFunctional(c.default || c))
export const MyProfileProductAlert = () => import('../../components/MyProfile/ProductAlert.vue' /* webpackChunkName: "components/my-profile-product-alert" */).then(c => wrapFunctional(c.default || c))
export const MyProfileProductVideo = () => import('../../components/MyProfile/ProductVideo.vue' /* webpackChunkName: "components/my-profile-product-video" */).then(c => wrapFunctional(c.default || c))
export const MyProfile = () => import('../../components/MyProfile/Profile.vue' /* webpackChunkName: "components/my-profile" */).then(c => wrapFunctional(c.default || c))
export const MyProfileQuizzes = () => import('../../components/MyProfile/Quizzes.vue' /* webpackChunkName: "components/my-profile-quizzes" */).then(c => wrapFunctional(c.default || c))
export const MyProfileSeances = () => import('../../components/MyProfile/Seances.vue' /* webpackChunkName: "components/my-profile-seances" */).then(c => wrapFunctional(c.default || c))
export const MyProfileSidebar = () => import('../../components/MyProfile/Sidebar.vue' /* webpackChunkName: "components/my-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const NewPassword = () => import('../../components/NewPassword/index.vue' /* webpackChunkName: "components/new-password" */).then(c => wrapFunctional(c.default || c))
export const PopupList = () => import('../../components/PopupList/index.vue' /* webpackChunkName: "components/popup-list" */).then(c => wrapFunctional(c.default || c))
export const ProductsFilterProduct = () => import('../../components/Products/FilterProduct.vue' /* webpackChunkName: "components/products-filter-product" */).then(c => wrapFunctional(c.default || c))
export const ProductsList = () => import('../../components/Products/List.vue' /* webpackChunkName: "components/products-list" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductCard = () => import('../../components/Products/ProductCard.vue' /* webpackChunkName: "components/products-product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductCardV2 = () => import('../../components/Products/ProductCardV2.vue' /* webpackChunkName: "components/products-product-card-v2" */).then(c => wrapFunctional(c.default || c))
export const Products = () => import('../../components/Products/index.vue' /* webpackChunkName: "components/products" */).then(c => wrapFunctional(c.default || c))
export const PurchaseAlreadyHaveProduct = () => import('../../components/Purchase/AlreadyHaveProduct.vue' /* webpackChunkName: "components/purchase-already-have-product" */).then(c => wrapFunctional(c.default || c))
export const PurchaseProductDetail = () => import('../../components/Purchase/ProductDetail.vue' /* webpackChunkName: "components/purchase-product-detail" */).then(c => wrapFunctional(c.default || c))
export const Purchase = () => import('../../components/Purchase/index.vue' /* webpackChunkName: "components/purchase" */).then(c => wrapFunctional(c.default || c))
export const ReadBook = () => import('../../components/ReadBook/index.vue' /* webpackChunkName: "components/read-book" */).then(c => wrapFunctional(c.default || c))
export const RemainingModalsEvent = () => import('../../components/RemainingModals/Event.vue' /* webpackChunkName: "components/remaining-modals-event" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptySearch = () => import('../../components/Search/EmptySearch.vue' /* webpackChunkName: "components/search-empty-search" */).then(c => wrapFunctional(c.default || c))
export const SearchList = () => import('../../components/Search/List.vue' /* webpackChunkName: "components/search-list" */).then(c => wrapFunctional(c.default || c))
export const SearchCard = () => import('../../components/Search/SearchCard.vue' /* webpackChunkName: "components/search-card" */).then(c => wrapFunctional(c.default || c))
export const SearchForm = () => import('../../components/Search/SearchForm.vue' /* webpackChunkName: "components/search-form" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search/index.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SharedCheckbox = () => import('../../components/Shared/Checkbox.vue' /* webpackChunkName: "components/shared-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../components/SignUp/index.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const Testimonials = () => import('../../components/Testimonials/index.vue' /* webpackChunkName: "components/testimonials" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerAssignment = () => import('../../components/VideoPlayer/Assignment.vue' /* webpackChunkName: "components/video-player-assignment" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/VideoPlayer/Player.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerSidebar = () => import('../../components/VideoPlayer/Sidebar.vue' /* webpackChunkName: "components/video-player-sidebar" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/VideoPlayer/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const VideoContent = () => import('../../components/VideoPlayer/VideoContent.vue' /* webpackChunkName: "components/video-content" */).then(c => wrapFunctional(c.default || c))
export const VideoTitle = () => import('../../components/VideoPlayer/VideoTitle.vue' /* webpackChunkName: "components/video-title" */).then(c => wrapFunctional(c.default || c))
export const VideoSecjs = () => import('../../components/VideoPlayer/videoSecjs.vue' /* webpackChunkName: "components/video-secjs" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerVideojs = () => import('../../components/VideoPlayer/videojs.vue' /* webpackChunkName: "components/video-player-videojs" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookCategories = () => import('../../components/Contents/BookContent/BookCategories.vue' /* webpackChunkName: "components/contents-book-categories" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookComments = () => import('../../components/Contents/BookContent/BookComments.vue' /* webpackChunkName: "components/contents-book-comments" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookDescription = () => import('../../components/Contents/BookContent/BookDescription.vue' /* webpackChunkName: "components/contents-book-description" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookImage = () => import('../../components/Contents/BookContent/BookImage.vue' /* webpackChunkName: "components/contents-book-image" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookMostPopularProducts = () => import('../../components/Contents/BookContent/BookMostPopularProducts.vue' /* webpackChunkName: "components/contents-book-most-popular-products" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookOverview = () => import('../../components/Contents/BookContent/BookOverview.vue' /* webpackChunkName: "components/contents-book-overview" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookTabs = () => import('../../components/Contents/BookContent/BookTabs.vue' /* webpackChunkName: "components/contents-book-tabs" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookTags = () => import('../../components/Contents/BookContent/BookTags.vue' /* webpackChunkName: "components/contents-book-tags" */).then(c => wrapFunctional(c.default || c))
export const ContentsBookContent = () => import('../../components/Contents/BookContent/index.vue' /* webpackChunkName: "components/contents-book-content" */).then(c => wrapFunctional(c.default || c))
export const ContentsCourseAuthors = () => import('../../components/Contents/CourseContent/CourseAuthors.vue' /* webpackChunkName: "components/contents-course-authors" */).then(c => wrapFunctional(c.default || c))
export const ContentsCourseFeatures = () => import('../../components/Contents/CourseContent/CourseFeatures.vue' /* webpackChunkName: "components/contents-course-features" */).then(c => wrapFunctional(c.default || c))
export const ContentsCourseOverview = () => import('../../components/Contents/CourseContent/CourseOverview.vue' /* webpackChunkName: "components/contents-course-overview" */).then(c => wrapFunctional(c.default || c))
export const ContentsCoursePrice = () => import('../../components/Contents/CourseContent/CoursePrice.vue' /* webpackChunkName: "components/contents-course-price" */).then(c => wrapFunctional(c.default || c))
export const ContentsCourseTitle = () => import('../../components/Contents/CourseContent/CourseTitle.vue' /* webpackChunkName: "components/contents-course-title" */).then(c => wrapFunctional(c.default || c))
export const ContentsCourseVideos = () => import('../../components/Contents/CourseContent/CourseVideos.vue' /* webpackChunkName: "components/contents-course-videos" */).then(c => wrapFunctional(c.default || c))
export const ContentsCourseContent = () => import('../../components/Contents/CourseContent/index.vue' /* webpackChunkName: "components/contents-course-content" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventAuthors = () => import('../../components/Contents/EventContent/EventAuthors.vue' /* webpackChunkName: "components/contents-event-authors" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventComingSoon = () => import('../../components/Contents/EventContent/EventComingSoon.vue' /* webpackChunkName: "components/contents-event-coming-soon" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventFeatures = () => import('../../components/Contents/EventContent/EventFeatures.vue' /* webpackChunkName: "components/contents-event-features" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventItems = () => import('../../components/Contents/EventContent/EventItems.vue' /* webpackChunkName: "components/contents-event-items" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventOverview = () => import('../../components/Contents/EventContent/EventOverview.vue' /* webpackChunkName: "components/contents-event-overview" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventPrice = () => import('../../components/Contents/EventContent/EventPrice.vue' /* webpackChunkName: "components/contents-event-price" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventTitle = () => import('../../components/Contents/EventContent/EventTitle.vue' /* webpackChunkName: "components/contents-event-title" */).then(c => wrapFunctional(c.default || c))
export const ContentsEventContent = () => import('../../components/Contents/EventContent/index.vue' /* webpackChunkName: "components/contents-event-content" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionCompanyIsSchool = () => import('../../components/Contents/PurchaseAction/CompanyIsSchool.vue' /* webpackChunkName: "components/contents-purchase-action-company-is-school" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductGetOffer = () => import('../../components/Contents/PurchaseAction/ProductGetOffer.vue' /* webpackChunkName: "components/contents-purchase-action-product-get-offer" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductHasBoughtAlready = () => import('../../components/Contents/PurchaseAction/ProductHasBoughtAlready.vue' /* webpackChunkName: "components/contents-purchase-action-product-has-bought-already" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductInformation = () => import('../../components/Contents/PurchaseAction/ProductInformation.vue' /* webpackChunkName: "components/contents-purchase-action-product-information" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseAction = () => import('../../components/Contents/PurchaseAction/ProductPurchaseAction.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-action" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseEFT = () => import('../../components/Contents/PurchaseAction/ProductPurchaseEFT.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-e-f-t" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseFreeAction = () => import('../../components/Contents/PurchaseAction/ProductPurchaseFreeAction.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-free-action" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseInformation = () => import('../../components/Contents/PurchaseAction/ProductPurchaseInformation.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-information" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseIyzico = () => import('../../components/Contents/PurchaseAction/ProductPurchaseIyzico.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-iyzico" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseNoFound = () => import('../../components/Contents/PurchaseAction/ProductPurchaseNoFound.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-no-found" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchasePaypal = () => import('../../components/Contents/PurchaseAction/ProductPurchasePaypal.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-paypal" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchaseActionProductPurchaseWise = () => import('../../components/Contents/PurchaseAction/ProductPurchaseWise.vue' /* webpackChunkName: "components/contents-purchase-action-product-purchase-wise" */).then(c => wrapFunctional(c.default || c))
export const ContentsPurchase = () => import('../../components/Contents/PurchaseAction/Purchase.vue' /* webpackChunkName: "components/contents-purchase" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceContentFastLogin = () => import('../../components/Contents/SeanceContent/FastLogin.vue' /* webpackChunkName: "components/contents-seance-content-fast-login" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceContentPaymentEFT = () => import('../../components/Contents/SeanceContent/PaymentEFT.vue' /* webpackChunkName: "components/contents-seance-content-payment-e-f-t" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceContentRezzervationConnection = () => import('../../components/Contents/SeanceContent/RezzervationConnection.vue' /* webpackChunkName: "components/contents-seance-content-rezzervation-connection" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceAuthors = () => import('../../components/Contents/SeanceContent/SeanceAuthors.vue' /* webpackChunkName: "components/contents-seance-authors" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceCard = () => import('../../components/Contents/SeanceContent/SeanceCard.vue' /* webpackChunkName: "components/contents-seance-card" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceCustomerPackages = () => import('../../components/Contents/SeanceContent/SeanceCustomerPackages.vue' /* webpackChunkName: "components/contents-seance-customer-packages" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceFeatures = () => import('../../components/Contents/SeanceContent/SeanceFeatures.vue' /* webpackChunkName: "components/contents-seance-features" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceList = () => import('../../components/Contents/SeanceContent/SeanceList.vue' /* webpackChunkName: "components/contents-seance-list" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceOverview = () => import('../../components/Contents/SeanceContent/SeanceOverview.vue' /* webpackChunkName: "components/contents-seance-overview" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeancePrice = () => import('../../components/Contents/SeanceContent/SeancePrice.vue' /* webpackChunkName: "components/contents-seance-price" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceTitle = () => import('../../components/Contents/SeanceContent/SeanceTitle.vue' /* webpackChunkName: "components/contents-seance-title" */).then(c => wrapFunctional(c.default || c))
export const ContentsSeanceContent = () => import('../../components/Contents/SeanceContent/index.vue' /* webpackChunkName: "components/contents-seance-content" */).then(c => wrapFunctional(c.default || c))
export const ContentsSimilarProducts = () => import('../../components/Contents/SimilarProducts/index.vue' /* webpackChunkName: "components/contents-similar-products" */).then(c => wrapFunctional(c.default || c))
export const SharedAccordion = () => import('../../components/Shared/Accordion/index.vue' /* webpackChunkName: "components/shared-accordion" */).then(c => wrapFunctional(c.default || c))
export const SharedCookiesCookieBar = () => import('../../components/Shared/Cookies/CookieBar.vue' /* webpackChunkName: "components/shared-cookies-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const SharedFooterBottom = () => import('../../components/Shared/Footer/FooterBottom.vue' /* webpackChunkName: "components/shared-footer-bottom" */).then(c => wrapFunctional(c.default || c))
export const SharedFooterCopyright = () => import('../../components/Shared/Footer/FooterCopyright.vue' /* webpackChunkName: "components/shared-footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const SharedFooterSubscription = () => import('../../components/Shared/Footer/Subscription.vue' /* webpackChunkName: "components/shared-footer-subscription" */).then(c => wrapFunctional(c.default || c))
export const SharedFooter = () => import('../../components/Shared/Footer/index.vue' /* webpackChunkName: "components/shared-footer" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlogContent = () => import('../../components/Contents/BlogContent/BlogContent.vue' /* webpackChunkName: "components/contents-blog-content" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlogImage = () => import('../../components/Contents/BlogContent/BlogImage.vue' /* webpackChunkName: "components/contents-blog-image" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlogIsParked = () => import('../../components/Contents/BlogContent/BlogIsParked.vue' /* webpackChunkName: "components/contents-blog-is-parked" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlogTags = () => import('../../components/Contents/BlogContent/BlogTags.vue' /* webpackChunkName: "components/contents-blog-tags" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlogTitle = () => import('../../components/Contents/BlogContent/BlogTitle.vue' /* webpackChunkName: "components/contents-blog-title" */).then(c => wrapFunctional(c.default || c))
export const SharedHeaderAdminLogin = () => import('../../components/Shared/Header/HeaderAdminLogin.vue' /* webpackChunkName: "components/shared-header-admin-login" */).then(c => wrapFunctional(c.default || c))
export const SharedHeaderBottom = () => import('../../components/Shared/Header/HeaderBottom.vue' /* webpackChunkName: "components/shared-header-bottom" */).then(c => wrapFunctional(c.default || c))
export const SharedHeaderLogo = () => import('../../components/Shared/Header/HeaderLogo.vue' /* webpackChunkName: "components/shared-header-logo" */).then(c => wrapFunctional(c.default || c))
export const SharedHeaderTop = () => import('../../components/Shared/Header/HeaderTop.vue' /* webpackChunkName: "components/shared-header-top" */).then(c => wrapFunctional(c.default || c))
export const SharedHeaderMenuArea = () => import('../../components/Shared/Header/MenuArea.vue' /* webpackChunkName: "components/shared-header-menu-area" */).then(c => wrapFunctional(c.default || c))
export const SharedHeader = () => import('../../components/Shared/Header/index.vue' /* webpackChunkName: "components/shared-header" */).then(c => wrapFunctional(c.default || c))
export const SharedInnerPageTitle = () => import('../../components/Shared/InnerPageTitle/index.vue' /* webpackChunkName: "components/shared-inner-page-title" */).then(c => wrapFunctional(c.default || c))
export const SharedLoadingInnerLoading = () => import('../../components/Shared/Loading/InnerLoading.vue' /* webpackChunkName: "components/shared-loading-inner-loading" */).then(c => wrapFunctional(c.default || c))
export const SharedLoadingPreloader = () => import('../../components/Shared/Loading/Preloader.vue' /* webpackChunkName: "components/shared-loading-preloader" */).then(c => wrapFunctional(c.default || c))
export const SharedModal = () => import('../../components/Shared/Modal/index.vue' /* webpackChunkName: "components/shared-modal" */).then(c => wrapFunctional(c.default || c))
export const SharedScrollToTop = () => import('../../components/Shared/ScrollToTop/index.vue' /* webpackChunkName: "components/shared-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const SharedSearchInnerSearch = () => import('../../components/Shared/Search/InnerSearch.vue' /* webpackChunkName: "components/shared-search-inner-search" */).then(c => wrapFunctional(c.default || c))
export const SharedWhatsappWidget = () => import('../../components/Shared/WhatsappWidget/index.vue' /* webpackChunkName: "components/shared-whatsapp-widget" */).then(c => wrapFunctional(c.default || c))
export const SharedNoFound = () => import('../../components/Shared/NoFound/index.vue' /* webpackChunkName: "components/shared-no-found" */).then(c => wrapFunctional(c.default || c))
export const SharedUploadDropzone = () => import('../../components/Shared/Upload/Dropzone.vue' /* webpackChunkName: "components/shared-upload-dropzone" */).then(c => wrapFunctional(c.default || c))
export const SharedUploadHomeworkList = () => import('../../components/Shared/Upload/HomeworkList.vue' /* webpackChunkName: "components/shared-upload-homework-list" */).then(c => wrapFunctional(c.default || c))
export const SharedUpload = () => import('../../components/Shared/Upload/index.vue' /* webpackChunkName: "components/shared-upload" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizMustFinishVideo = () => import('../../components/VideoPlayer/Quiz/MustFinishVideo.vue' /* webpackChunkName: "components/video-player-quiz-must-finish-video" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizNextVideoNotFound = () => import('../../components/VideoPlayer/Quiz/NextVideoNotFound.vue' /* webpackChunkName: "components/video-player-quiz-next-video-not-found" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizOtherResultsOfQuiz = () => import('../../components/VideoPlayer/Quiz/OtherResultsOfQuiz.vue' /* webpackChunkName: "components/video-player-quiz-other-results-of-quiz" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizIsDone = () => import('../../components/VideoPlayer/Quiz/QuizIsDone.vue' /* webpackChunkName: "components/video-player-quiz-is-done" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizIsStarted = () => import('../../components/VideoPlayer/Quiz/QuizIsStarted.vue' /* webpackChunkName: "components/video-player-quiz-is-started" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizQuestions = () => import('../../components/VideoPlayer/Quiz/QuizQuestions.vue' /* webpackChunkName: "components/video-player-quiz-questions" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizStartQuiz = () => import('../../components/VideoPlayer/Quiz/StartQuiz.vue' /* webpackChunkName: "components/video-player-quiz-start-quiz" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuizStartQuizModal = () => import('../../components/VideoPlayer/Quiz/StartQuizModal.vue' /* webpackChunkName: "components/video-player-quiz-start-quiz-modal" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerQuiz = () => import('../../components/VideoPlayer/Quiz/index.vue' /* webpackChunkName: "components/video-player-quiz" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
