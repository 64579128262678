export const state = () => ({
  categories: [],
})
export const getters = {
  getCategories: (state) => state.categories,
}
export const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
}
export const actions = {
  async GET_CATEGORIES({ commit }) {
    const response = await this.$api.get('Categories')
    console.log(response, "response from categories");
    
    if (response === undefined) return this.$router.push('/error?code=500')
    if (response.message === 'OK') commit('SET_CATEGORIES', response.data.items)
    return true
  },
}
