import CryptoJS from 'gurhan/helpers/Crypto'
const Crypto = new CryptoJS(process.env.HMU_MAKETODAYAMAZING)
export const state = () => ({
  userIP: {},
  activeUser: {},
  activeAdminUser: {},
  heocademyToken:
    'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjIiLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInJvbGVJZCI6IjEiLCJuYmYiOjE3MDgxMTg2NDAsImV4cCI6MTc0NDQwNjY0MCwiaXNzIjoiaGVvY2FkZW15LmNvbSIsImF1ZCI6Imhlb2NhZGVteS5jb20ifQ.Zjjfd4i0XltOe_laHcmgDA5ksREGGMp7H7oCiaWm-RM',
  autoLogin: {
    status: false,
    email: '',
    password: '',
  },
})
export const getters = {
  getUserIP: (state) => state.userIP, // ({ timezone: 'Europe/Berlin', offset: 10800 - 3600, currency: 'EUR'}),
  getActiveUser: (state) => ({ isActive: 1, ...state.activeUser }),
  getActiveAdmin: (state) => ({ isActive: 1, ...state.activeAdminUser }),
  getAutoLogin: (state) => state.autoLogin,
  getHeocademyToken: (state) => state.heocademyToken,
}
export const mutations = {
  SET_HEOCADEMY_TOKEN(state, payload) {
    state.heocademyToken = payload
  },
  AUTO_LOGIN(state, payload) {
    state.autoLogin = {
      status: true,
      ...payload,
    }
  },
  REMOVE_AUTO_LOGIN(state, payload) {
    state.autoLogin = {
      status: false,
    }
  },
  SET_USER_IP(state, payload) {
    state.userIP = {
      ...payload,
      offset: payload.offset === 0 ? 1 : payload.offset,
    }
  },
  SET_ACTIVE_USER(state, payload) {
    state.activeUser = payload
  },
  SET_ACTIVE_ADMIN_USER(state, payload) {
    state.activeAdminUser = payload
  },
  SET_UPDATE_USER(state, payload) {
    state.activeUser = {
      ...state.activeUser,
      ...payload,
    }
    SET_USER_DATA_TO_COOKIE(
      this,
      {
        ...state.activeUser,
        ...payload,
      },
      'hmu_tkn',
      0
    )
  },
  SET_LOGOUT(state) {
    this.$cookies.remove('hmu_tkn')
    this.$cookies.remove('hmu_admin_tkn')
    this.$router.push(this.localePath('/giris-yap'))
    state.activeUser = {}
    state.activeAdminUser = {}
  },
}
export const actions = {
  SET_LOGOUT({ commit }) {
    commit('SET_LOGOUT')
    commit('courses/SET_CLEAR_IT', {}, { root: true })
    commit('events/SET_CLEAR_IT', {}, { root: true })
    commit('productDetail/SET_CLEAR_IT', {}, { root: true })
    commit('products/SET_CLEAR_IT', {}, { root: true })
    commit('profile.books/SET_CLEAR_IT', {}, { root: true })
    commit('profile.courses/SET_CLEAR_IT', {}, { root: true })
    commit('profile.events/SET_CLEAR_IT', {}, { root: true })
    commit('profile.seances/SET_CLEAR_IT', {}, { root: true })
    commit('seances/SET_CLEAR_IT', {}, { root: true })
    commit('books/SET_CLEAR_IT', {}, { root: true })
    commit('api/SET_TOKEN', '', { root: true })
  },
  async GET_IP({ commit }) {
    const ipfromCookie = '' //SET_TO_CONVERT_USER_DATA_FROM_COOKIE(this, 'hmu_usr_info');
    if (ipfromCookie === '') {
      const response = await this.$api.get('Users/GetIPInfo');
      console.log(response, "response from getip");
      
      if (response?.message === 'OK') {
        commit('SET_USER_IP', response.data)
        SET_USER_DATA_TO_COOKIE(this, response.data, 'hmu_usr_info')
      } else commit('SET_USER_IP', userIP)
    } else {
      commit('SET_USER_IP', ipfromCookie)
    }
    return true
  },
  async SEND_USER_FOR_REGISTER(
    { rootState },
    { fd, rezForm, definations, filesDefinitions }
  ) {
    const isSeances = rootState.settings.isSeances
    const rezResponse =
      isSeances === 1
        ? await CREATE_USER_TO_REZZ_APP(rezForm)
        : { message: 'OK', data: 1 }
    if (rezResponse.message === 'OK') {
      fd.append('RemoteId', rezResponse.data)
      const response = await this.$api.post('Users/Register', fd)
      if (response.message === 'OK') {
        await this.$api.post(
          'MockDatas',
          definations.map((d) => ({ ...d, dataId: response.data }))
        )
        for (let i = 0; i < filesDefinitions.length; i++) {
          const element = filesDefinitions[i]
          const fd = new FormData()
          fd.append('code', element.code)
          fd.append('dataType', element.dataType)
          fd.append('value', element.file)
          fd.append('dataId', response.data)
          await this.$api.post('MockDatas/AddFile', fd)
        }
        return {
          status: true,
          message: 'valid.insertedUser',
          variant: 'success',
        }
      } else if (response.message === 'REGISTERED')
        return {
          status: true,
          message: 'valid.registeredUser',
          variant: 'danger',
        }
      else
        return { status: true, message: 'valid.failedUser', variant: 'danger' }
    } else if (rezResponse.message === 'REGISTERED')
      return {
        status: true,
        message: 'valid.registeredUser',
        variant: 'danger',
      }
    else {
      return { status: true, message: 'valid.failedUser', variant: 'danger' }
    }
  },
  async SEND_TO_LOGIN(vuexmodule, form) {
    const response = await this.$api.post('Users/Login', form)
    if (response.message === 'OK') {
      vuexmodule.dispatch('SET_USER_ACTION', { payload: response.data })
      vuexmodule.commit('courses/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('events/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('productDetail/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('products/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.books/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.courses/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.events/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.seances/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('seances/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('books/SET_CLEAR_IT', {}, { root: true })
      return {
        status: true,
        message: '',
        variant: 'success',
      }
    } else if (response.message === 'LOGINERROR')
      return {
        status: true,
        message: 'valid.wrongPassword',
        variant: 'danger',
      }
    else return { status: true, message: 'valid.failedUser', variant: 'danger' }
  },
  async SEND_TO_ADMIN_LOGIN(vuexmodule, form) {
    const response = await this.$api.post('Users/AdminLogin', form)
    if (response.message === 'OK') {
      vuexmodule.dispatch('SET_ADMIN_ACTION', { payload: response.data })
      vuexmodule.commit('courses/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('events/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('productDetail/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('products/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.books/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.courses/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.events/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('profile.seances/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('seances/SET_CLEAR_IT', {}, { root: true })
      vuexmodule.commit('books/SET_CLEAR_IT', {}, { root: true })
      return {
        status: true,
        message: '',
        variant: 'success',
      }
    } else if (response.message === 'LOGINERROR')
      return {
        status: true,
        message: 'valid.wrongPassword',
        variant: 'danger',
      }
    else return { status: true, message: 'valid.failedUser', variant: 'danger' }
  },
  SET_USER_ACTION({ commit }, { payload, setCookie = true }) {
    commit('SET_ACTIVE_USER', payload)
    if (setCookie) SET_USER_DATA_TO_COOKIE(this, payload, 'hmu_tkn', 7)
    commit('api/SET_TOKEN', payload.token, { root: true })
  },
  SET_ADMIN_ACTION({ commit }, { payload, setCookie = true }) {
    commit('SET_ACTIVE_ADMIN_USER', payload)
    if (setCookie) SET_USER_DATA_TO_COOKIE(this, payload, 'hmu_admin_tkn', 7)
    //  commit('api/SET_TOKEN', payload.token, { root: true })
  },
  CONTROL_OF_USER({ dispatch }) {
    const userfromCookie = SET_TO_CONVERT_USER_DATA_FROM_COOKIE(this, 'hmu_tkn')
    if (userfromCookie === '') return false
    dispatch('SET_USER_ACTION', { payload: userfromCookie, setCookie: false })
    return true
  },
  CONTROL_OF_ADMIN({ dispatch }) {
    const adminFromCookie = SET_TO_CONVERT_USER_DATA_FROM_COOKIE(
      this,
      'hmu_admin_tkn'
    )
    if (adminFromCookie === '') return false
    dispatch('SET_ADMIN_ACTION', { payload: adminFromCookie, setCookie: false })
    return true
  },
  async SEND_TO_UPDATE_USER({ commit }, { user, definations }) {
    const fd = new FormData()
    fd.append('Email', user.email)
    fd.append('Fullname', user.fullname)
    fd.append('IsActive', 1)
    fd.append('Phone', user.phone)
    const response = await this.$api.put(`Users/${user.id}`, fd)
    if (response.message === 'OK') {
      commit('SET_UPDATE_USER', user)
      // await this.$api.put('mockDatas', definations);
      return { status: true, variant: 'success', message: 'valid.updated' }
    } else if (response.message === 'REGISTERED')
      return {
        status: true,
        variant: 'danger',
        message: 'valid.registeredUserInUpdate',
      }
    else return { status: true, variant: 'danger', message: 'valid.error' }
  },
  async SEND_TO_UPDATE_CHANGE_PASSWORD({ dispatch }, payload) {
    const response = await this.$api.put('Users/ChangePassword', payload)
    if (response.message === 'OK') {
      setTimeout(() => {
        dispatch('SET_LOGOUT')
      }, 2500)
      return { status: true, variant: 'success', message: 'valid.updated' }
    } else if (response.message === 'WRONGOLDPASSWORD')
      return {
        status: true,
        variant: 'danger',
        message: 'valid.wrongOldPassword',
      }
    else return { status: true, variant: 'danger', message: 'valid.error' }
  },
}

// rezzervasyon uyulamasına kayıt olmak için.
const CREATE_USER_TO_REZZ_APP = (data) => {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  var raw = JSON.stringify(data)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  return fetch(
    'https://api.rezzervasyon.com/Customers/ExternalCreate',
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error)
}

const userIP = {
  country: 'Turkey',
  countryCode: 'TR',
  city: 'İzmir',
  cityCode: '35',
  currency: 'USD',
  languageCode: 'TR',
}
const SET_USER_DATA_TO_COOKIE = (
  { $cookies },
  payload,
  key,
  day = 7,
  isObject = true
) => {
  let cryptedData = ''
  if (isObject) cryptedData = Crypto.crypto(JSON.stringify(payload))
  else cryptedData = Crypto.crypto(payload)
  $cookies.set(key, cryptedData, {
    path: '/',
    maxAge: 60 * 60 * 24 * day, // {day} gün süreli cookie.
  })
  return true
}

const SET_TO_CONVERT_USER_DATA_FROM_COOKIE = (
  { $cookies },
  key,
  isObject = true
) => {
  const cookieRes = $cookies.get(key)
  let decryptedData = ''
  if (cookieRes && isObject)
    decryptedData = JSON.parse(Crypto.decrypto(cookieRes))
  else if (cookieRes && !isObject) decryptedData = Crypto.decrypto(cookieRes)
  return decryptedData
}
